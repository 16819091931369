import React from "react";
import "./SystemCheck.css";
import icon from "./logo.svg";
import DetectRTC from "detectrtc";
import swal from "sweetalert";
import ExampleComponent from "../technicalvalidation/ExampleComponent";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CheckCircle } from "@mui/icons-material";

var buttonViewDisabled = true;

function ValidateCheck() {
  var isAllowed = false;

  // Browser Check
  if (DetectRTC.browser.isChrome) {
    //.................................Chrome
    // If Browser is Chrome
    if (DetectRTC.browser.version > 80) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isFirefox) {
    //.................................Firefox
    // If Browser is Chrome
    if (DetectRTC.browser.version > 60) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isSafari) {
    //.................................Safari
    // If Browser is Chrome
    if (DetectRTC.browser.version > 12) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isOpera) {
    //.................................Opera
    // If Browser is Chrome
    if (DetectRTC.browser.version > 60) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isEdge) {
    //.................................Edge
    // If Browser is Chrome
    if (DetectRTC.browser.version > 80) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }

  // OS Check
  // Note: Not sure WHat we are going to do with This

  // Camera Permission
  DetectRTC.load(function () {
    const webcam = DetectRTC.isWebsiteHasWebcamPermissions;
    if (!webcam) {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      var video = document.querySelector("#videoElement");
      if (navigator.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            video.srcObject = stream;
          })

          .catch(function (err0r) {
            //console.log("Something went wrong!");
          });
      }
    }
  });

  const webcam = DetectRTC.isWebsiteHasWebcamPermissions;
  //console.log(webcam)
  if (webcam) {
    isAllowed = true;
  } else {
    isAllowed = false;
  }

  // Final Approval
  if (isAllowed) {
    buttonViewDisabled = false;
  } else {
    buttonViewDisabled = true;
  }
}

const SystemCheck = () => {
  // System Detections Package
  var DetectRTC = require("detectrtc");

  ValidateCheck();

  function handleClick() {}

  var elem = document.documentElement;

  return (
    <body className="App-header">
      <div className="main">
        <table align="center">
          <tbody>
            <tr>
              <td>
                <ul>
                  <li className="test">
                    <span>
                      <b>Operating System:</b>{" "}
                      {" " +
                        JSON.stringify(DetectRTC.osName, null, 2).slice(1, -1) +
                        " " +
                        JSON.stringify(DetectRTC.osVersion, null, 0).slice(
                          1,
                          -1
                        )}{" "}
                      <CheckCircle color="success" style={{ fontSize: 18 }} />
                    </span>
                  </li>
                  <li className="test">
                    <span>
                      <b>Browser:</b>{" "}
                      {" " +
                        JSON.stringify(DetectRTC.browser.name).slice(1, -1) +
                        " " +
                        JSON.stringify(DetectRTC.browser.version)}{" "}
                    </span>
                    <CheckCircle color="success" style={{ fontSize: 18 }} />
                  </li>
                  {/* <li>
                    <span>
                      <b>Camera:</b>The lighting in the room must be bright
                      enough to be considered “daylight” quality.
                    </span><CheckCircleOutlineIcon/>
                  </li> */}
                  <li>
                    <span>
                      <ExampleComponent />
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
  );
};

export default SystemCheck;
