import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAdvertisements } from "../../../store/applicant/actions";

import AdvertSkeleton from "../components/comm/AdvertSkeleton";

import AdvertisementItem from "../components/AdvertisementItem";
import ReactPaginate from "react-paginate";
import NoResults from "../components/comm/NoResults";
import SearchBox from "../components/comm/SearchBox";
import AdvertisementDetails from "../components/AdvertisementDetails";
import RightSideContent from "../../home/components/RightSideContent";

export const Advertisements = (props) => {
  const {
    loading,
    onWait,
    user,
    advertisements,
    getAdvertisements,
    selectedAdvertisement,
  } = props;
  const dispatch = useDispatch();

  const [advertisementItem, setAdvertisementItem] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);

  // PAGINATION
  // const itemsPerPage = 10;
  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + itemsPerPage;
  // const [paginatedJobs, setPaginatedJobs] = useState([]);
  // const [pageCount, setPageCount] = useState(1);

  // useEffect(() => {
  //   setPaginatedJobs(filteredJobs.slice(itemOffset, endOffset));
  //   setPageCount(
  //     Math.ceil(
  //       filteredJobs.filter((item) => !item.isTimeOut).length / itemsPerPage
  //     )
  //   );
  // }, [filteredJobs, itemOffset, endOffset]);

  // const handlePageClick = (event) => {
  //   const newOffset =
  //     (event.selected * itemsPerPage) %
  //     (filteredJobs ? filteredJobs.length : 1);
  //   setItemOffset(newOffset);
  // };

  useEffect(() => {
    setFilteredJobs(
      advertisements.filter((advertisement) =>
        (
          (advertisement.positionName || "") +
          (advertisement.entityName || "") +
          (advertisement.entityAcronym || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchInput.replace(/\s/g, "").toLowerCase())
      )
    );
  }, [advertisements, searchInput]);

  useEffect(() => {
    if (!advertisements.length) getAdvertisements();
  }, []);

  const randomArray = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 10)
  );

  return (
    <>
      {/* list of advertise */}
      <div className="row mt-4 mx-0 ">
        {/* profile summary */}
        <div className="col-12 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
          {/* <ProfileSummary /> */}

          <RightSideContent />
        </div>
        {/* advertisement list */}
        <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-3 mt-sm-0 px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary bg-light mt-4">
            <div className="card-header bg-light rounded-top font-weight-bold text-uppercase">
              <div className="row justify-content-sm-between align-items-center">
                <div className="col-12 col-sm-5">
                  <Typography variant="h6">
                    New Job Advertisements ({advertisements.length})
                  </Typography>
                </div>
                <div className="col-12 col-sm-4 mt-1 mt-md-0">
                  <SearchBox
                    placeholder="Search..."
                    disabled={loading || advertisements.length === 0}
                    onSearch={setSearchInput}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading && !onWait && !advertisements.length ? (
                randomArray.map((item) => (
                  <div className="mb-3" key={item + Math.random() * 11}>
                    <AdvertSkeleton />
                  </div>
                ))
              ) : filteredJobs.length ? (
                filteredJobs.map((advertisement, index) => (
                  <div className="mb-3" key={advertisement.id}>
                    <AdvertisementItem advertisement={advertisement} />
                  </div>
                ))
              ) : (
                <NoResults />
              )}

              {/* {advertisements.length >= itemsPerPage && (
                  <div
                    className="justify-content-center mb-3"
                    style={{ overflow: "hidden" }}
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </div>

      {!!selectedAdvertisement &&
        !!advertisements.find(({ id }) => id === selectedAdvertisement.id) && (
          <AdvertisementDetails
            showDialog={!!selectedAdvertisement}
            setShowDialog={(status) =>
              dispatch({
                type: "SET_SELECTED_ADVERTISEMENT",
                data: null,
              })
            }
            advertisement={selectedAdvertisement}
          />
        )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  advertisements,
  selectedAdvertisement,
}) => ({
  user,
  loading,
  onWait,
  advertisements,
  selectedAdvertisement,
});

export default connect(mapStateToProps, { getAdvertisements })(Advertisements);
