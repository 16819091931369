import Test from './components/Test'

const TestComponent = ({
  totalScore,
  setTotalScore,
  onSubmitTest,
  onCancel,
  advertisement,
}) => {
  return (
    <Test
      totalScore={totalScore}
      setTotalScore={setTotalScore}
      onSubmitTest={onSubmitTest}
      onCancel={onCancel}
      advertisement={advertisement}
    />
  )
}

export default TestComponent
