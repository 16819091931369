import React, { useState } from "react";
import { Grid, Autocomplete, TextField, Chip } from "@mui/material";

const HandleMatching = ({
  selectedQuestion,
  selectedLanguage,
  submitQuestionAnswer,
  isLastQuestion,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectedQuestion.options.map((item) => ({
      optionNumber: item.optionNumber,
      matchEn: "", // Initial value
      matchFr: "", // Initial value
      matchKn: "", // Initial value
    }))
  );

  const selectedValueOption = (optionNumber) => {
    const selected = selectedOptions.find(
      (itm) => itm.optionNumber === optionNumber
    );
    return selected === undefined
      ? {
          optionNumber: optionNumber,
          matchEn: "",
          matchFr: "",
          matchKn: "",
        }
      : selected;
  };

  const getMatchesList = () => {
    const response = [];
    for (const item of selectedQuestion.options) {
      const match = response.find((option) => option.matchEn === item.matchEn);
      if (match === undefined) {
        response.push({
          matchEn: item.matchEn,
          matchFr: item.matchFr,
          matchKn: item.matchKn,
        });
      }
    }
    return response;
  };

  const handleChange = (event, newValue, optionNumber) => {
    console.log({ newValue: newValue, optionNumber: optionNumber });
    setSelectedOptions([
      ...selectedOptions.filter((itm) => itm.optionNumber !== optionNumber),
      {
        optionNumber: optionNumber,
        matchEn: newValue.matchEn,
        matchFr: newValue.matchFr,
        matchKn: newValue.matchKn,
      },
    ]);
  };
  return (
    <div>
      <div className="pl-4 mb-4">
        {console.log({
          selectedOptions: selectedQuestion.options,
          selectedData: selectedOptions,
        })}
        <div>
          {selectedQuestion.options.map((option, i) => (
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              key={i + 1}
              style={{
                marginTop: "10px",
                paddingTop: "0px",
                paddingBottom: "15px",
                paddingRight: "15px",
                borderRadius: 5,
                backgroundColor: "white",
              }}
            >
              <Grid item xs={12} md={6}>
                <div style={{ height: "100%" }}>{`${option.optionNumber}) ${
                  selectedLanguage === "English"
                    ? option.en
                    : selectedLanguage === "French"
                    ? option.fr
                    : option.kn
                }`}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple={false}
                  options={getMatchesList()}
                  getOptionLabel={(option) =>
                    selectedLanguage === "English"
                      ? option.matchEn
                      : selectedLanguage === "French"
                      ? option.matchFr
                      : option.matchKn
                  }
                  value={{
                    optionNumber: option.optionNumber,
                    matchEn: selectedValueOption(option.optionNumber).matchEn,
                    matchFr: selectedValueOption(option.optionNumber).matchFr,
                    matchKn: selectedValueOption(option.optionNumber).matchKn,
                  }}
                  onChange={(e, data) =>
                    handleChange(e, data, option.optionNumber)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Option"
                      variant="outlined"
                      placeholder="Search..."
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={
                          selectedLanguage === "English"
                            ? option.matchEn
                            : selectedLanguage === "French"
                            ? option.matchFr
                            : option.matchKn
                        }
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                />
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
      <div
        className="mt-2 bg-primary pl-4 pr-4 pt-2 pb-2 rounded-pill text-white"
        style={{ width: "max-content", cursor: "pointer" }}
        onClick={() => {
          // Handle marks
          var hasMarks = true;
          for (const test of selectedOptions) {
            const theCorrectOption = selectedQuestion.options.find(
              (itm) => itm.optionNumber === test.optionNumber
            );
            if (
              theCorrectOption === undefined ||
              theCorrectOption.matchEn !== test.matchEn
            ) {
              hasMarks = false;
              break;
            }
          }
          submitQuestionAnswer({
            questionId: selectedQuestion.questionId,
            score: hasMarks === true ? selectedQuestion.score : 0, // To be calculated
          });

          console.log({ marksGot: selectedQuestion.score });
        }}
      >
        {isLastQuestion === true ? "Save & Submit the Test" : "Save & Continue"}
      </div>
    </div>
  );
};

export default HandleMatching;
