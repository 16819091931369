import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";

const ConfirmationDialog = (props) => {
  const {
    message,
    setConfirmationDialog,
    loading,
    confirmationDialog,
    onYes,
    noPreFormat,
    error,
    btnMessage,
    isLoading,
    disabled,
    onWait,
    color,
    title,
    hideCloseBtn,
    icon,
    score,
  } = props;

  return (
    <>
      <Dialog
        maxWidth="xs"
        onClose={() => {
          setConfirmationDialog(false);
        }}
        open={confirmationDialog}
      >
        <DialogTitle className={`text-${error ? "danger" : "primary"}`}>
          <span className="d-flex align-items-center">
            {!error && (
              <span className="material-icons mr-1">{icon || "error"}</span>
            )}
            {error && (
              <span className="material-icons mr-1">{icon || "warning"}</span>
            )}

            {error ? title || "Error" : title || "Confirm"}
          </span>{" "}
          {!hideCloseBtn && (
            <IconButton
              aria-label="close"
              onClick={() => {
                setConfirmationDialog(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          className={`pb-0 text-center ${error ? "text-danger" : ""} `}
        >
          {/* {loading || isLoading ? <LinearProgress />:''} */}
          {noPreFormat ? (
            message
          ) : (
            <pre
              style={{
                fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </pre>
          )}
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "140px",
                width: "140px",
                borderRadius: "50%",
                backgroundColor: score >= 50 ? "green" : "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              <div>
                Total Score:
                <div style={{ fontSize: "25px" }}>
                  {parseFloat(score).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            // size="small"
            //disabled={loading || isLoading || disabled || onWait}
            color={color || "success"}
            variant="contained"
            onClick={onYes}
            className="ml-2 px-4"
          >
            {loading || onWait ? "Wait..." : btnMessage || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait }) => {
  return { loading, onWait };
};
export default connect(mapStateToProps)(ConfirmationDialog);
