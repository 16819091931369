import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import colors from "../../utils/colors";
import moment from "moment/moment";
import { connect, useDispatch } from "react-redux";
import AdvertisementDetails from "./AdvertisementDetails";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  hideOrUnhideApplication,
  getSingleApplication,
} from "../../../store/applicant/actions";

import ConfirmationDialog from "./comm/ConfirmationDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
function ApplicationItem(props) {
  const {
    user,
    env,
    loading,
    application,
    hideTime,
    roundedValue,
    isForView,
    isHome,
    hideOrUnhideApplication,
    getSingleApplication,
  } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showAdvertisementDetails, setShowAdvertisementDetails] =
    useState(false);
  const [showReason, setShowReason] = useState(false);
  const [showLastStatusMessage, setShowLastStatusMessage] = useState(false);

  const [
    showAllCandidatesShortlistedDialog,
    setShowAllCandidatesShortlistedDialog,
  ] = useState(false);
  const [
    showAllCandidatesOnWrittenDialog,
    setShowAllCandidatesOnWrittenDialog,
  ] = useState(false);
  const [showAllCandidatesOnOralDialog, setShowAllCandidatesOnOralDialog] =
    useState(false);
  const [showAllCandidatesResultsDialog, setShowAllCandidatesResultsDialog] =
    useState(false);

  const [
    showAllCandidatesOnWaitingDialog,
    setShowAllCandidatesOnWaitingDialog,
  ] = useState(false);

  const [showAppealPreviewDialog, setShowAppealPreviewDialog] = useState(false);
  const [showAppealForm, setShowAppealForm] = useState(false);

  const [appealTypeId, setAppealTypeId] = useState(null);
  const [appealLevelId, setAppealLevelId] = useState(null);

  const [appealIdToShow, setAppealIdToShow] = useState(null);

  const [confirmHideOrUnhideApplication, setConfirmHideOrUnhideApplication] =
    useState(false);

  const [showJobOfferDialog, setShowJobOfferDialog] = useState(false);
  const [showWrittenExamDetail, setShowWrittenExamDetail] = useState(false);
  const [showOralExamDetail, setShowOralExamDetail] = useState(false);

  return (
    <>
      <Card
        className={`border ${roundedValue || "rounded"} bg-light`}
        elevation={isForView ? 0 : 1}
      >
        <CardHeader
          className="pb-0"
          title={
            <>
              <small
                style={{ fontSize: "9px" }}
                className="badge badge-pill d-block px-0 text-left"
              >
                <small>#{application.id}</small>
              </small>
              <Typography
                sx={{ fontSize: 16 }}
                variant="h6"
                color={colors.blueLogo}
                className="  d-flex justify-content-between"
              >
                <span
                  onClick={() => {
                    if (!isForView) setShowAdvertisementDetails(true);
                  }}
                  className="cursor-pointer "
                >
                  <span className="text-underline ">
                    {application.positionName}
                  </span>{" "}
                </span>
              </Typography>
            </>
          }
          subheader={
            <Typography variant="h6" component="div" sx={{ fontSize: 13 }}>
              <span className="text-capitalize">
                {application.entityName?.toLowerCase()}{" "}
                {!!application.entityAcronym && (
                  <>({application.entityAcronym})</>
                )}
              </span>
            </Typography>
          }
          action={
            !isForView && (
              <div className="text-right">
                <span className="d-flex align-items-center">
                  {application.isHidden && (
                    <span className="badge badge-pill text-secondary">
                      Hidden
                    </span>
                  )}

                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleOpenMenu}
                    aria-label="settings"
                  >
                    <Badge color="info">
                      <MoreHorizIcon />
                    </Badge>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 2,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <span>
                      <MenuItem
                        disabled={loading}
                        onClick={() => {
                          handleCloseMenu();
                          setConfirmHideOrUnhideApplication(true);
                        }}
                        className="text-dark font-weight-light"
                      >
                        {!application.isHidden && (
                          <VisibilityOffIcon className="mr-1" />
                        )}
                        {application.isHidden && (
                          <VisibilityIcon className="mr-1" />
                        )}
                        {application.isHidden ? "Unhide" : "Hide"}
                      </MenuItem>
                    </span>
                  </Menu>
                </span>
              </div>
            )
          }
        />
        <CardContent className="pt-0">
          <div className="d-flex align-items-top flex-wrap">
            {isForView && (
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Posted on</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(application.openingDate).format("ll")}
                </Typography>
              </div>
            )}
            {isForView && (
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <AccessTimeOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Deadline</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(application.closingDate).format("ll")}
                </Typography>
              </div>
            )}
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Level:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {`${application.levelName}.${application.scaleName}`}
              </Typography>
            </div>
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Post{application.numberOfPosts > 1 ? "s" : ""}:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {application.numberOfPosts}
              </Typography>
            </div>
            <span>
              <span
                className={`badge badge-pill border`}
                style={{ fontSize: 10 }}
              >
                {application.employeeGroupName}
              </span>{" "}
              {application.isInternal && (
                <span className="badge badge-info ml-2">Internal</span>
              )}
            </span>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-column flex-sm-row justify-content-start align-items-center flex-wrap">
              <div className="mr-2">
                <Typography
                  variant="caption"
                  style={{ color: colors?.blueLogo }}
                >
                  Applied on:
                </Typography>
                <Typography variant="caption" className="font-weight-bold">
                  {moment(application.appliedOn).format("lll")}
                </Typography>
              </div>
              <Divider />
              <div
                style={{ borderRadius: "6px" }}
                className="border bg-white d-flex flex-wrap align-items-center"
              >
                <span>
                  <span
                    className={`badge badge-pill text-${
                      application.apStatusId === 0
                        ? "secondary"
                        : application.apStatusId === 1
                        ? "success"
                        : "danger"
                    }`}
                  >
                    <span className="d-inline-flex align-items-center">
                      {application.apStatusId === 0 && (
                        <i className="fas fa-clock"></i>
                      )}
                      {application.apStatusId === 1 && (
                        <i className="fas fa-check-circle"></i>
                      )}
                      {[2, 3].includes(application.apStatusId) && (
                        <i className="fas fa-times-circle"></i>
                      )}
                      <span
                        onClick={() =>
                          setShowAllCandidatesShortlistedDialog(true)
                        }
                        className="text-underline cursor-pointer"
                      >
                        {application.apStatusName}{" "}
                      </span>
                    </span>
                    {(application.apStatusId === 2 || application.apStatusId === 3) && (
                      <span
                        onClick={() => setShowReason(true)}
                        className="badge badge-pill btn-link cursor-pointer"
                      >
                        Reason
                      </span>
                    )}

                    {!!application.shortListAppealId && (
                      <span
                        onClick={() => {
                          setAppealIdToShow(application.shortListAppealId);
                          setShowAppealPreviewDialog(true);
                        }}
                        className="badge badge-pill text-warning font-weight-bold text-uppercase text-underline cursor-pointer"
                      >
                        Appealed
                      </span>
                    )}
                  </span>
                </span>

                {application.adStatusId === 8 && (
                  <>
                    <span
                      className={`badge badge-pill cursor-pointer text-underline text-danger`}
                      onClick={() => setShowLastStatusMessage(true)}
                    >
                      <i
                        className="fas fa-times-circle"
                        style={{ paddingRight: "2px" }}
                      ></i>

                      {application.adStatusName}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </CardContent>
      </Card>

      {showReason && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showReason}
          onClose={() => setShowReason(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowReason(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              className="mt-4 bg-light"
              fullWidth
              label="Reason for rejection"
              placeholder="Reason for rejection"
              value={application.reason || " "}
              multiline
              minRows={4}
            />
          </DialogContent>
          <DialogActions className=""></DialogActions>
        </Dialog>
      )}

      {showLastStatusMessage && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showLastStatusMessage}
          onClose={() => setShowLastStatusMessage(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowLastStatusMessage(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              className="mt-4 bg-light"
              fullWidth
              label="Reason for cancellation"
              placeholder="Reason for cancellation"
              value={application.lastStatusMessage || " "}
              multiline
              minRows={4}
            />
          </DialogContent>
          <DialogActions className=""></DialogActions>
        </Dialog>
      )}

      {showAdvertisementDetails && (
        <AdvertisementDetails
          showDialog={showAdvertisementDetails}
          setShowDialog={setShowAdvertisementDetails}
          advertisement={application}
        />
      )}

      {confirmHideOrUnhideApplication && (
        <ConfirmationDialog
          btnMessage={`${application.isHidden ? "unhide" : "hide"}`}
          confirmationDialog={confirmHideOrUnhideApplication}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="mx-1">
                {application.isHidden ? "unhide" : "hide"}
              </strong>{" "}
              this application?
            </>
          }
          setConfirmationDialog={setConfirmHideOrUnhideApplication}
          onYes={() => {
            hideOrUnhideApplication(
              {
                applicationId: application.id,
                isHidden: !application.isHidden,
              },
              () => setConfirmHideOrUnhideApplication(false)
            );
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ user, env, loading }) => ({
  user,
  env,
  loading,
});

export default connect(mapStateToProps, {
  hideOrUnhideApplication,
  getSingleApplication,
})(ApplicationItem);
